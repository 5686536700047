<template>
    <Modal @close="$emit('close')">
      <div slot="body">
        {{ this.$t('after_save_reaction') }}
        <br><br>
        <!-- Check if recommendations array is empty -->
        <template v-if="recommendations.length === 0">
          {{ this.$t('no_recommendations') }}
        </template>

        <!-- Loop through recommendations and render clickable links -->
        <ul v-else>
          <li v-for="(item, index) in recommendations" :key="index">
            <a
              :href="generateAmazonLink(cleanRecommendation(item))"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ item }}
            </a>
          </li>
        </ul>
      </div>
              
    </Modal>
</template>

<script>
import Modal from './Modal.vue'
export default {
  name: 'Recommendations',
  components: {
    Modal
  },
  data () {
    return {}
  },
  props: ['name', 'parent', 'recommendations'],

  methods: {
    generateAmazonLink(recommendation) {
      // Construct the Amazon search link for the recommendation
      const amazonLink = `https://www.amazon.com/s?tag=legi016-20&k=${encodeURIComponent(
        recommendation + ' kindle audiobook'
      )}`;
      return amazonLink;
    },
    cleanRecommendation(recommendation) {
      // Clean the recommendation text by removing special characters except accents
      return recommendation.replace(/[^\w\sÀ-ÿ]/g, '');
    },
  }
}
</script>

<style scoped>

.buttons {
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  border-radius: 6px;
  font-family: Arial;
  color: #00073F;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  border: solid #00073F 2px;
  text-decoration: none;
  width: 30vh;
  margin: 1vh
}

button:hover {
  text-decoration: none;
}

li {
  display: block;
  list-style: circle;
  text-align: left;
  padding-bottom: 3%;
  font-weight: bold;
}
</style>

<template>
    <Modal @close="$emit('close')">
      <div slot="body">
        <button class="buttons" @click="
            $emit('font_plus')
          ">{{ $t("font_plus") }}
        </button> <br>
        <button class="buttons" @click="
            $emit('font_less')
          ">{{ $t("font_less") }}
        </button> <br>
        <!-- <button class="buttons"
          @click="alert($t('howto') + '\n' + $t('howto_1'))">
          {{$t("howto")}}
        </button><br> -->
        <button @click="open_gplay" class="buttons">Android App</button><br>
        <button @click="open_instagram" class="buttons">Instagram</button><br>
        <button @click="reset_preferences" class="buttons">
          {{$t("reset_preferences_title")}}  
        </button><br>

        <div style="padding-top: 20px;">{{$t("authorship")}}</div>
      </div>
              
    </Modal>
</template>

<script>
import Modal from './Modal.vue'
export default {
  name: 'Settings',
  components: {
    Modal
  },
  data () {
    return {
      showModal: false
    }
  },
  props: ['name', 'parent'],

  methods: {
    open_gplay() {
      window.open('https://play.google.com/store/apps/details?id=org.legi', '_blank');
    },

    open_instagram() {
      window.open('https://instagram.com/legi.app', '_blank');
    },

    reset_preferences() {
      localStorage.setItem('user_random_id', '');
      alert(this.$t("reset_preferences"));
    }
  }
}
</script>

<style scoped>

.buttons {
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  border-radius: 6px;
  font-family: Arial;
  color: #00073F;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  border: solid #00073F 2px;
  text-decoration: none;
  width: 30vh;
  margin: 1vh
}

button:hover {
  text-decoration: none;
}

li {
  display: block;
}
</style>

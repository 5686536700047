<template>
  <div :class="['body', styles]">
    <h2 :style="{fontSize: fontSize+3 + 'px', textAlign: 'center'}">{{ titleText }}</h2>
    <h4 :style="{fontSize: fontSize-2 + 'px', textAlign: 'center'}">{{ authorText }}</h4>
    <div :style="{fontSize: fontSize + 'px'}">
      <p v-if="bodyText!=''" :style="{whiteSpace: 'pre-wrap', lineHeight: lineHeight + 'px'}">
        {{ bodyText }}
      </p>
      <p v-else-if="isLoading">
         <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto; animation-play-state: running; animation-delay: 0s;" width="84px" height="84px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <circle cx="50" cy="50" fill="none" stroke="#91d5ed" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" style="animation-play-state: running; animation-delay: 0s;">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" style="animation-play-state: running; animation-delay: 0s;"></animateTransform>
          </circle>
        </svg>
      </p>
      <p v-else style="text-align: center;">
        {{$t("intro_text_0")}}
        <br><br><br>
        {{$t("intro_text_1")}} <i :class="['fas', 'fa-book-open', 'menuicon']"></i>
        <br><br><br>
        {{$t("intro_text_2")}} <i :class="['fas', 'fa-headphones', 'menuicon']"></i>{{$t("intro_text_3")}}
        <br>
        {{$t("intro_text_4")}}
      </p>

      <div v-if="bodyText!=''" style="width: 100%; text-align: center;">
        <i :class="['fa-solid', 'fa-thumbs-up', styles]"
           v-bind:title="$t('like')"
           @click="sendReaction('like')"
           :style="{fontSize: 70 + 'px', cursor: 'pointer'}"
           >
        </i>
        <i :class="['fa-solid', 'fa-thumbs-down', styles]"
           v-bind:title="$t('dislike')"
           @click="sendReaction('dislike')"
           :style="{fontSize: 70 + 'px', cursor: 'pointer', marginLeft: 20 + '%'}"
           >
        </i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Body',
  props: ["bodyText", "titleText", "authorText", "styles", "fontSize",
          "lineHeight", "isLoading", "sendReaction"],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.body {
  clear: both;
  text-align: justify;
  margin-left: 8vw;
  margin-right: 8vw;
  padding-top: 5%;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
